import Container_ForkedRibbonAreaComponent from '../components/Container/viewer/skinComps/ForkedRibbonArea/ForkedRibbonArea.skin';


const Container_ForkedRibbonArea = {
  component: Container_ForkedRibbonAreaComponent
};


export const components = {
  ['Container_ForkedRibbonArea']: Container_ForkedRibbonArea
};

